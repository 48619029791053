<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-07-08 15:10:00
 * @LastEditTime: 2021-07-27 14:15:00
 * @Description: 工控与ERP关系配置
 * @Param:
 * @FilePath: \JT_Web_test\src\views\purchaseManage\rawMaterialManagement\IndustrialControlAndERP.vue
 -->
<template>
    <div class="IndustrialControlAndERP" v-loading="loading">
        <div class="stationProductionLine">
            <div class="station">
                <el-tabs v-model="stationSelection" @tab-click="handleClick">
                    <template v-for="(item, index) in stationData">
                        <el-tab-pane :key="`station_${index}`" :label="item.name" :name="item.station_id"></el-tab-pane>
                    </template>
                </el-tabs>
            </div>
            <div class="productionLine">
                <template v-for="(item, index) in productionLineData">
                    <el-button
                        plain
                        size="small"
                        type="primary"
                        :key="`line_${index}`"
                        :class="{'activeLine': stationLineNo == item.no}"
                        @click="lineSelection(item)"
                    >
                        {{ item.name }}
                    </el-button>
                </template>
            </div>
        </div>
        <div class="siloData">
            <div class="siloInfo">
                <p class="iconfont iconzhuangtai">
                    名称：{{ stationLineName }}
                </p>
                <p class="iconfont iconchengshi">
                    工控厂商：{{ stationLine.manufacturers }}
                </p>
                <p class="iconfont iconicon-up">
                    是否启用：{{ stationLine.is_enabled ? '启用' : '禁用' }}
                </p>
            </div>
            <div class="siloList">
                <table>
                    <thead>
                        <tr>
                            <th colspan="2" class="gk_thStyle">
                                工控系统
                            </th>
                            <th class="placeholder"></th>
                            <th colspan="6" class="erp_thStyle">
                                ERP系统
                            </th>
                            <th class="placeholder"></th>
                            <th rowspan="2" class="th_width1">
                                <el-button
                                    type="primary"
                                    @click="overallDelivery"
                                    :disabled="tableData.length === 0"
                                >
                                    整体发送
                                </el-button>
                            </th>
                        </tr>
                        <tr>
                            <td class="gk_tdStyle">
                                工控编号
                            </td>
                            <td class="gk_tdStyle">
                                工控料仓类型
                            </td>
                            <td class="placeholder"></td>
                            <td class="erp_tdStyle">
                                料仓名称
                            </td>
                            <td class="erp_tdStyle">
                                料仓号
                            </td>
                            <td class="erp_tdStyle">
                                料仓编码
                            </td>
                            <td class="erp_tdStyle">
                                子类名称
                            </td>
                            <td class="erp_tdStyle">
                                规格
                            </td>
                            <td class="erp_tdStyle">
                                操作
                            </td>
                            <td class="placeholder"></td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in tableData">
                            <tr :key="`table_${index}`">
                                <td>{{ item.plcw }}</td>
                                <td>{{ item.plcwname }}</td>
                                <td class="placeholder"></td>
                                <td class="erp_tdStyle" :class="{'notSelected': (item.stock_name || '') == ''}">
                                    {{ item.stock_name ? item.stock_name : '--待选择--' }}
                                </td>
                                <td class="erp_tdStyle" :class="{'notSelected': (item.stock_no || '') == ''}">
                                    {{ item.stock_no ? item.stock_no : '--待选择--' }}
                                </td>
                                <td class="erp_tdStyle" :class="{'notSelected': (item.stock_code || '') == ''}">
                                    {{ item.stock_code ? item.stock_code : '--待选择--' }}
                                </td>
                                <td class="erp_tdStyle" :class="{'notSelected': (item.material_child_name || '') == ''}">
                                    {{ item.material_child_name ? item.material_child_name : '--待选择--' }}
                                </td>
                                <td class="erp_tdStyle" :class="{'notSelected': (item.material_name || '') == ''}">
                                    {{ item.material_name ? item.material_name : '--待选择--' }}
                                </td>
                                <td>
                                    <el-button
                                        plain
                                        size="mini"
                                        type="warning"
                                        v-if="item.stock_no"
                                        @click="adjustBtn(item, index)"
                                    >
                                        调整
                                    </el-button>
                                    <el-button
                                        plain
                                        v-else
                                        size="mini"
                                        type="primary"
                                        @click="adjustBtn(item, index)"
                                    >
                                        选择
                                    </el-button>
                                    <el-button
                                        plain
                                        size="mini"
                                        type="danger"
                                        v-if="item.stock_no"
                                        @click="deleteAssociation(item)"
                                    >
                                        删除
                                    </el-button>
                                </td>
                                <td class="placeholder"></td>
                                <td class="th_width1">
                                    <p v-if="industrySiloData && industrySiloData.status === 2 && item.status === 1">
                                        已确认
                                    </p>
                                    <p v-else-if="item.status == 1" class="underConfirmation">
                                        确认中
                                    </p>
                                    <p v-else-if="item.status == 2" class="toBeSelected">
                                        待选择
                                    </p>
                                    <p v-else-if="item.status == 3" class="toBeSent">
                                        待发送
                                    </p>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'industrial-control-and-e-r-p',
    components: {},
    props: {
        extr: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            loading: false,
            // 页面场景
            scene: 'page',
            // 场站数据
            stationData: [],
            // 当前选中场站
            stationSelection: '',
            // 生产线数据
            productionLineData: [],
            // 选中生产线数据
            stationLine: {},
            // 当前选中生产线
            stationLineNo: '',
            // 当前选中生产线名城
            stationLineName: '',
            // 列表数据
            tableData: [],
            // 生产线数据
            industrySiloData: {},
            // 列表选择下标
            tableIndex: 0,
        };
    },
    computed: {},
    watch: {},
    created() {
        if (this.extr && this.extr.scene) {
            this.scene = this.extr.scene;
        }
        this.loading = true;
        if (this.scene === 'view') {
            this.stationSelection = this.extr.mixstation_code;
            this.stationLineNo = this.extr.line_code;
            this.getProductionLineData();
        } else {
            // 获取场站数据
            this.getstationData();
        }
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 场站切换
        handleClick(tab) {
            this.loading = true;
            this.stationSelection = this.stationData[tab.index].station_id;
            this.getProductionLineData();
        },
        // 生产线切换
        lineSelection(data) {
            this.loading = true;
            this.stationLine = data;
            this.stationLineName = data.name;
            this.stationLineNo = data.no;
            this.mixstationlinestock();
        },
        // 获取场站数据
        async getstationData() {
            const Uid = this.$takeTokenParameters('Uid');
            await this.$axios
                .get(`/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=${Uid}&roleCode=J05-05`)
                .then(res => {
                    if (res.length > 0) {
                        // 当前站点默认站点
                        this.stationData = res;
                        this.stationSelection = res[0].station_id;
                        this.getProductionLineData();
                    } else {
                        this.$message({
                            message: '暂无场站数据！',
                            type: 'warning',
                        });
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取上前场站下生产线数据
        getProductionLineData() {
            this.productionLineData = [];
            this.$axios
                .get(`/interfaceApi/BaseInfo/stationmanger/get_line_list?stationId=${this.stationSelection}`)
                .then(res => {
                    if (res.length > 0) {
                        this.productionLineData = res;
                        if (this.scene === 'view') {
                            this.productionLineData.forEach((item, index) => {
                                if (item.no === this.stationLineNo) {
                                    this.stationLine = res[index];
                                    this.stationLineName = res[index].name;
                                    this.mixstationlinestock();
                                }
                            });
                        } else {
                            this.stationLine = res[0];
                            this.stationLineNo = res[0].no;
                            this.stationLineName = res[0].name;
                            this.mixstationlinestock();
                        }
                    } else {
                        this.$message({
                            message: '当前场站没有生产线！',
                            type: 'warning',
                        });
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 工控生产线料仓列表
        async mixstationlinestock() {
            await this.$axios
                .get(`/interfaceApi/production/mixstationlinestock/stocks/${this.stationSelection}/${this.stationLineNo}`)
                .then(res => {
                    const tableList = JSON.parse(JSON.stringify(res));
                    this.getProductsilo(tableList);
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // erp关系获取
        async getProductsilo(data) {
            await this.$axios
                .get(`/interfaceApi/production/productsilo/productsilo/${this.stationSelection}/${this.stationLineNo}`)
                .then(res => {
                    this.industrySiloData = res.industrySilo || {};
                    data.forEach(item => {
                        if ((res.siloRelation || []).length > 0) {
                            const row = res.siloRelation.filter(k => k.plcw === item.plcw);
                            if (row.length > 0) {
                                item = Object.assign(item, row[0]);
                                if (item.plcw && item.stock_name) {
                                    item.status = 1;
                                }
                                if (item.plcw && !item.stock_name) {
                                    item.status = 2;
                                }
                            }
                        } else {
                            item.status = 2;
                        }

                    });

                    this.tableData = data;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 删除关联关系
        deleteAssociation(item) {
            item.stock_code = '';
            item.stock_name = '';
            item.stock_alias = '';
            item.stock_category = '';
            item.stock_category_dictcode = '';
            item.stock_category_name = '';
            item.stock_type = '';
            item.stock_type_name = '';
            item.stock_no = '';
            item.plcw_auto_no = '';
            item.material_code = '';
            item.material_bg_code = '';
            item.material_name = '';
            item.material_model = '';
            item.material_child_code = '';
            item.material_child_dictcode = '';
            item.material_child_name = '';
            item.source_goods_code = '';
            item.source_goods_name = '';
            item.status = 2;
        },
        // 点击调整按钮
        adjustBtn(item, index) {
            if (this.scene !== 'view') {
                this.tableIndex = index;
                const queryStr = 'mixstation_code=' + this.stationSelection + '&line_code=' + this.stationLineNo;
                this.$toast({
                    title: true,
                    text: '选择料仓',
                    type: 'eject',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'gklcxz',
                            QueryString: queryStr,
                            selectedCallback: this.productNumCallback,
                        },

                    },
                });
            }
        },
        // 弹窗数据回调
        productNumCallback(data) {
            if (Object.keys(data).length === 0) {
                this.$message({
                    message: '请先选择料仓',
                    type: 'warning',
                });
            } else {
                this.tableData[this.tableIndex] = Object.assign(this.tableData[this.tableIndex], data);
                this.tableData[this.tableIndex].status = 3;
            }
        },
        // 整体发送
        overallDelivery() {
            if (this.scene !== 'view' ) {
                this.loading = true;
                let siloNmuber;
                if (Object.keys(this.industrySiloData).length !== 0) {
                    siloNmuber = this.industrySiloData.industry_silo_number;
                } else {
                    siloNmuber = '-';
                }
                const titleObject = {
                    industry_silo_number: siloNmuber,
                    mixstation_code: this.stationSelection,
                    mixstation_name: this.stationLineName,
                    line_code: this.stationLineNo,
                    line_name: this.stationLineName,
                    scbt: this.stationLine.mixing_platform,
                    silo_count: this.tableData.length,
                    version: this.industrySiloData.version,
                    remarks: '',
                };
                const tableArray = [];
                this.tableData.forEach(item => {
                    const tableObject = {
                        source_goods_code: item.source_goods_code,
                        source_goods_name: item.source_goods_name,
                        industry_silo_number: siloNmuber,
                        plcw_auto_no: item.plcw_auto_no || '',
                        version: item.version,
                        pre_version: '前一配置关系版本',
                        effect_time: item.created,
                        stock_code: item.stock_code,
                        stock_name: item.stock_name,
                        stock_alias: item.stock_alias,
                        stock_category: item.stock_category,
                        stock_category_name: item.stock_category_name,
                        stock_category_dictcode: item.stock_category_dictcode,
                        stock_type: item.stock_type,
                        stock_type_name: item.stock_type_name,
                        stock_no: item.stock_no,
                        plcw: item.plcw,
                        plcwname: item.plcwname,
                        material_code: item.material_code,
                        material_name: item.material_name,
                        material_model: item.material_model,
                        material_child_name: item.material_child_name,
                        material_child_code: item.material_child_code,
                        material_child_dictcode: item.material_child_dictcode,
                        material_bg_code: item.material_bg_code,
                    };
                    tableArray.push(tableObject);
                });
                if (Object.keys(this.industrySiloData).length !== 0) {
                    this.productsiloModify(titleObject, tableArray);
                } else {
                    this.productsiloAdd(titleObject, tableArray);
                }
            }
        },
        // 修改工控料仓关系
        async productsiloModify(title, table) {
            await this.$axios
                .post('/interfaceApi/production/productsilo/modify', {
                    industrySiloInput: title,
                    siloRelationInput: table,
                })
                .then(res => {
                    this.baocunSunccess = res;
                    this.productsiloSend();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 整体发送
        productsiloSend() {
            this.$axios
                .put(`/interfaceApi/production/productsilo/send/${this.industrySiloData.industry_silo_number}`)
                .then(() => {
                    this.$message.success('修改成功');
                    this.mixstationlinestock();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加工控料仓关系
        async productsiloAdd(title, table) {
            await this.$axios
                .post('/interfaceApi/production/productsilo/add', {
                    industrySiloInput: title,
                    siloRelationInput: table,
                })
                .then(res => {
                    this.baocunSunccess = res;
                    this.$message.success('保存成功');
                    this.mixstationlinestock();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.IndustrialControlAndERP
    height 100%
    .stationProductionLine
        height 1.11rem
        margin-bottom 0.2rem
        background #fff
        .station
            >>>.el-tabs
                .el-tabs__header
                    margin-bottom 0
                .el-tabs__nav-wrap
                    padding-left 0.2rem
                    .el-tabs__nav
                        height 0.5rem
                        line-height 0.5rem
                        .el-tabs__item
                            height 0.5rem
                            line-height 0.5rem
                            font-size 0.16rem
                            font-weight 500
                            &.is-active,
                            &:hover
                                color #D6000F
                        .el-tabs__active-bar
                            background-color #D6000F
        .productionLine
            padding 0.15rem 0.2rem
            height 0.62rem
            >>>.el-button
                height 0.32rem
                padding 0 0.15rem
                line-height 0.32rem
                &+.el-button
                    margin-left 0.2rem
                &.activeLine
                    background #5588F1
                    color #fff
    .siloData
        background #fff
        padding 0.2rem 0 0.2rem 0.2rem
        height calc(100% - 1.31rem)
        .siloInfo
            display flex
            margin-bottom 0.15rem
            p
                font-size 0.16rem
                line-height 0.16rem
                color #333333
                padding 0 0.4rem
                position relative
                &:first-child
                    padding-left 0
                &:after
                    content ''
                    height 0.16rem
                    border-right 1px solid #B9ACA2
                    position absolute
                    right 0
                    top 0
                &:last-child
                    &:after
                        display none
                &.iconzhuangtai
                    &:before
                        color #5588F1
                &.iconchengshi
                    &:before
                        color #EB656F
                &.iconicon-up
                    &:before
                        color #FE7903
        .siloList
            height calc(100% - 0.31rem)
            padding-right 0.2rem
            overflow-y auto
            table
                width 100%
                border-collapse collapse
                th,td
                    height 0.36rem
                    text-align center
                    border 1px solid #D7D7D7
                td
                    width 2.2rem
                    font-size 0.14rem
                    color #333333
                thead
                    th,td
                        font-size 0.16rem
                    .gk_thStyle
                        background #EDF0F5
                        color #022782
                        border-color #D7D7D7
                    .gk_tdStyle
                        background #F6F8FC
                        color #022782
                    .erp_thStyle
                        background #F5F2ED
                        color #823802
                        border-color #D6CEC9
                    .erp_tdStyle
                        background #FCF9F6
                        color #823802
                        border-color #D6CEC9
                    .th_width1
                        border-top 1px solid #D7D7D7
                        border-bottom 1px solid #D7D7D7
                        .el-button
                            width 100%
                            height 0.6rem
                            font-size 0.16rem
                tbody
                    th
                        width 2.2rem
                    .erp_tdStyle
                        border-color #D6CEC9
                        color #823802
                    tr
                        &:last-child
                            .th_width1
                                border-bottom 1px solid #D7D7D7
                    .notSelected
                        color #C2C2C2
                .th_width1
                    width 2.3rem
                    border-top none
                    border-bottom none
                    padding 0 0.1rem
                    p
                        height 0.3rem
                        font-size 0.14rem
                        color #333333
                        line-height 0.3rem
                        border 1px solid #D7D7D7
                        &.underConfirmation
                            color #02AAC7
                        &.toBeSent
                            color #FE7903
                            border-color #FE7903
                        &.toBeSelected
                            color #5588f1
                            border-color #C2C2C2
                .placeholder
                    width 8px
                    border none
                    background #EDF0F6
</style>
